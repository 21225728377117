import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function SEO({ children, title }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);
  return (
    <Helmet titleTemplate={`%s | ${site.siteMetadata.title}`}>
      <html lang="en" />
      <title>{title}</title>
      <link rel="icons" type="image/svg+html" href="/favicon.svg" />
      <link rel="alternate icon" href="/favicon.ico" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700"
        rel="stylesheet"
      />
      <meta name="viewport" content="width=device-width, intital-scale=1.0" />
      <meta charset="utf-8" />
      <meta description={site.siteMetadata.description} />
      <meta property="og:url" content="www.x-rd.com.au" />
      <meta
        property="og:image"
        content={`${site.siteMetadata.siteUrl}/full-logo.svg`}
      />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta
        property="og:site_name"
        content={site.siteMetadata.title}
        key="ogsitename"
      />
      <meta
        property="og:description"
        content={site.siteMetadata.description}
        key="ogdesc"
      />
      {children}
    </Helmet>
  );
}
